import { AsyncPipe } from '@angular/common';
import {
  Component,
  effect,
  ElementRef,
  HostBinding,
  input,
  model,
  Renderer2,
  signal,
  ViewChild,
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { debounceTime } from 'rxjs';

import { SafePipe } from '@nghedgehog/angular-ui';

@Component({
  selector: 'my-iframe',
  templateUrl: './my-iframe.component.html',
  styleUrls: ['./my-iframe.component.scss'],
  standalone: true,
  imports: [MatProgressSpinnerModule, AsyncPipe, SafePipe],
})
export class MyIFrameComponent {
  @HostBinding('class') classList = 'block relative';
  title = input('iframe');
  src = model<string | undefined>();
  height = model<string | undefined>();
  width = model<string | undefined>();
  attributes = signal<{
    [key: string]: string;
  }>({});

  iframeClass = input<
    | {
        [key: string]: string | number;
      }
    | undefined
  >();
  @ViewChild('frame', { static: true }) frameElement!: ElementRef;

  loaded = signal(false);

  // iframe loaded event will call many times, so debounce it
  debounceLoaded$ = toObservable(this.loaded).pipe(debounceTime(500));

  constructor(private _render: Renderer2) {
    effect(() => {
      const attr = this.attributes();

      const keys = Object.keys(attr);
      if (keys.length > 0) {
        keys.forEach((key) => {
          this._render.setAttribute(
            this.frameElement.nativeElement,
            key,
            attr[key],
          );
        });
      }
    });
  }

  iframeLoaded() {
    this.loaded.set(true);
  }
}
