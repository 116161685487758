<iframe
  #frame
  [src]="src() | safe: 'resourceUrl'"
  frameborder="0"
  [style.height]="height()"
  [style.width]="width()"
  marginheight="0"
  [title]="title()"
  marginwidth="0"
  (load)="iframeLoaded()"
  class="inline-block"
></iframe>
@if ((debounceLoaded$ | async) !== true) {
  <div
    class="flex absolute left-0 top-0 justify-center items-center w-full h-full"
  >
    <mat-progress-spinner
      aria-progressbar-name="load iframe in progress"
      color="accent"
      mode="indeterminate"
    ></mat-progress-spinner>
  </div>
}
